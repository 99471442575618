import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { Chart } from 'primereact/components/chart/Chart';

export const Shared = () => {
    const [userDataGender, setUserDataGender] = useState({});
    const bgColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
    ];

    const bdColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ];
    const bdWidth = 1;
    var test_api = {
        method: 'GET',
        url: '../assets/demo/data/shared.json',
    };
    const ApiDataGender = () => {
        let dos1 = 0;
        let dos2 = 0;
        let dos3 = 0;
        let dos4 = 0;
        let dos5 = 0;
        let dos6 = 0;

        axios.request(test_api)
        .then(res=> {
            for(let i = 0; i < res.data.data.length; i++) {
                if(res.data.data[i].type === "type_1") {
                    dos1 = res.data.data[i].value;
                }

                if(res.data.data[i].type === "type_2") {
                    dos2 = res.data.data[i].value;
                }

                if(res.data.data[i].type === "type_3") {
                    dos3 = res.data.data[i].value;
                }

                if(res.data.data[i].type === "type_4") {
                    dos4 = res.data.data[i].value;
                }

                if(res.data.data[i].type === "type_5") {
                    dos5 = res.data.data[i].value;
                }

                if(res.data.data[i].type === "type_6") {
                    dos6 = res.data.data[i].value;
                }
            }

            setUserDataGender({
                labels: ['DoS 1', 'DoS 2', 'DoS 3', 'DoS 4', 'DoS 5', 'DoS 6'],
                datasets: [{
                    label: 'DoS Data',
                    data: [dos1, dos2, dos3, dos4, dos5, dos6],
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }
    useEffect(() => {
        ApiDataGender();
    }, []);
    return (
        <div>
            <div className="p-grid dashboard">
                <div className="p-col-12 p-lg-12">
                <h1>Shared Data Reporting</h1>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                    <div className="card">
                        <h4>Selected Threat Location</h4>
                        <Chart type="bar" data={userDataGender} options={{
                            legend: {
                                display: false
                            },
                            responsive:true,
                            title: { text: "Data Reports For DoS Incidents", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}/>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                    <div className="card">
                        <h4>Selected Threat Detail</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}
