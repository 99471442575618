import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';

export class CreateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportsBusy: false,
            mDate: null,
            mDateTo: null,
            selectedReport: null
        };

        this.report = [
            { name: 'Tactical', code: 'TAC' },
            { name: 'Operational', code: 'OPS' },
            { name: 'Strategic', code: 'STR' },
            { name: 'All', code: 'ALL' }
        ];

        this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
        this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successfully generated the required reports!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful in generating the required reports!', life: 5000});
    }

    onReportChange(e) {
        this.setState({ selectedReport: e.value });
    }

    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}}>
                        <ProgressSpinner/>
                        <p>Busy generating reports</p>
                    </Card>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            <h5>Setup reports to be generated below.</h5>
                        </div>
                        <div className="p-fluid p-grid p-formgrid" style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}} >
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="from">From Date</label>
                                <Calendar id="from" value={this.state.mDate} onChange={(e) => this.setState({ mDate: e.value })} showIcon showTime showSeconds touchUI dateFormat="dd-mm-yy"/>
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="to">To Date</label>
                                <Calendar id="to" value={this.state.mDateTo} onChange={(e) => this.setState({ mDateTo: e.value })} showIcon showTime showSeconds touchUI dateFormat="dd-mm-yy"/>
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="to">Select report type</label>
                                <Dropdown value={this.state.selectedReport} options={this.report} onChange={(e) => this.onReportChange(e)} optionLabel="name" placeholder="Select report type" />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                            <Button className="primary" label="Create Report" onClick={(e) => {
                                e.preventDefault();
                                this.createSelectedReports();
                            }} />
                        </div>
                        <br/>
                        <Card style={{'background': '#F0F3F6'}} title="Report Type Definitions">
                            <p>Tactical Report: CTI at the tactical level focuses on the what. Indicators of Compromise (IOC) such as file names, file hashes, domain names, IP addresses, and much more, define the what of CTI.  The what takes the form of internally generated data which resides in Security Operations Centers (SOC) and, more often than not, is organized into multiple trusted feeds that are hopefully tuned to the organization’s environment</p>
                            <p>Operational Report: CTI at the operational level addresses the how and where. The observed threat actor Tactics, Techniques, and Procedures (TTP) define the how of CTI. The how goes beyond just an alert, or series of alerts. The how assists organizations to understand the extent of a breach, or prepare defense in advance. </p>
                            <p>Strategic Report: Focuses on who is behind a specific threat/family of threats, while addressing ongoing trends. Also focuses on why a specific malicious cyber actor (MCA) or group of MCAs is interested in an organization.</p>
                        </Card>
                    </Card>
                </React.Fragment>
            );
        }
    }

    async createSelectedReports() {
        if(this.state.selectedReport.name === "All") {
            this.createAllReports();
        } else if(this.state.selectedReport.name === "Tactical") {
            this.createTacticalReport();
        } else if(this.state.selectedReport.name === "Operational") {
            this.createOperationalReport();
        } else if(this.state.selectedReport.name === "Strategic") {
            this.createStrategicReports();
        }
    }

    /**
     *
     * @returns
     * let dateFrom = this.formatDate(this.state.mDate);
        let dateTo = this.formatDate(this.state.mDateTo);
        console.log("Formatted Date From: ", dateFrom);
        console.log("Formatted Date From: ", dateTo);
     */


    async gotoDashboard() {
        this.props.history.push({
            pathname: '/dashboard'
        });
    }

    async createAllReports() {
        let actualMonthFrom = (parseInt((new Date(this.state.mDate)).getMonth()) + 1).toString();
        let actualMonthTo = (parseInt((new Date(this.state.mDateTo)).getMonth()) + 1).toString();

        let fromDate = (new Date(this.state.mDate)).getUTCFullYear() + "-" +
            actualMonthFrom + "-" + (new Date(this.state.mDate)).getDate() + "T" +
            (new Date(this.state.mDate)).getHours() + ":" +
            (new Date(this.state.mDate)).getMinutes() + ":" +
            (new Date(this.state.mDate)).getSeconds();

        let toDate = (new Date(this.state.mDateTo)).getUTCFullYear()  + "-" +
            actualMonthTo + "-" + (new Date(this.state.mDateTo)).getDate()  + "T" +
            (new Date(this.state.mDateTo)).getHours() + ":" +
            (new Date(this.state.mDateTo)).getMinutes() + ":" +
            (new Date(this.state.mDateTo)).getSeconds();

        this.setState({reportsBusy: true});
        this.productServiceDataSources.createReports(fromDate, toDate);
        setTimeout(() => {
            if(this.productServiceDataSources.getReportResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 100000);
    }

    async createTacticalReport() {
        this.setState({reportsBusy: true});
        let actualMonthFrom = (parseInt((new Date(this.state.mDate)).getMonth()) + 1).toString();
        let actualMonthTo = (parseInt((new Date(this.state.mDateTo)).getMonth()) + 1).toString();

        let fromDate = (new Date(this.state.mDate)).getUTCFullYear() + "-" +
            actualMonthFrom + "-" + (new Date(this.state.mDate)).getDate() + "T" +
            (new Date(this.state.mDate)).getHours() + ":" +
            (new Date(this.state.mDate)).getMinutes() + ":" +
            (new Date(this.state.mDate)).getSeconds();

        let toDate = (new Date(this.state.mDateTo)).getUTCFullYear()  + "-" +
            actualMonthTo + "-" + (new Date(this.state.mDateTo)).getDate()  + "T" +
            (new Date(this.state.mDateTo)).getHours() + ":" +
            (new Date(this.state.mDateTo)).getMinutes() + ":" +
            (new Date(this.state.mDateTo)).getSeconds();

        this.productServiceDataSources.createTacReports(fromDate, toDate);
        setTimeout(() => {
            if(this.productServiceDataSources.getTacReportResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 20000);
    }

    async createOperationalReport() {
        this.setState({reportsBusy: true});
        let actualMonthFrom = (parseInt((new Date(this.state.mDate)).getMonth()) + 1).toString();
        let actualMonthTo = (parseInt((new Date(this.state.mDateTo)).getMonth()) + 1).toString();

        let fromDate = (new Date(this.state.mDate)).getUTCFullYear() + "-" +
            actualMonthFrom + "-" + (new Date(this.state.mDate)).getDate() + "T" +
            (new Date(this.state.mDate)).getHours() + ":" +
            (new Date(this.state.mDate)).getMinutes() + ":" +
            (new Date(this.state.mDate)).getSeconds();

        let toDate = (new Date(this.state.mDateTo)).getUTCFullYear()  + "-" +
            actualMonthTo + "-" + (new Date(this.state.mDateTo)).getDate()  + "T" +
            (new Date(this.state.mDateTo)).getHours() + ":" +
            (new Date(this.state.mDateTo)).getMinutes() + ":" +
            (new Date(this.state.mDateTo)).getSeconds();

        this.productServiceDataSources.createOpsReports(fromDate, toDate);
        setTimeout(() => {
            if(this.productServiceDataSources.getOpsReportResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 20000);
    }

    async createStrategicReports() {
        this.setState({reportsBusy: true});
        let actualMonthFrom = (parseInt((new Date(this.state.mDate)).getMonth()) + 1).toString();
        let actualMonthTo = (parseInt((new Date(this.state.mDateTo)).getMonth()) + 1).toString();

        let fromDate = (new Date(this.state.mDate)).getUTCFullYear() + "-" +
            actualMonthFrom + "-" + (new Date(this.state.mDate)).getDate() + "T" +
            (new Date(this.state.mDate)).getHours() + ":" +
            (new Date(this.state.mDate)).getMinutes() + ":" +
            (new Date(this.state.mDate)).getSeconds();

        let toDate = (new Date(this.state.mDateTo)).getUTCFullYear()  + "-" +
            actualMonthTo + "-" + (new Date(this.state.mDateTo)).getDate()  + "T" +
            (new Date(this.state.mDateTo)).getHours() + ":" +
            (new Date(this.state.mDateTo)).getMinutes() + ":" +
            (new Date(this.state.mDateTo)).getSeconds();

        this.productServiceDataSources.createStratReports(fromDate, toDate);
        setTimeout(() => {
            if(this.productServiceDataSources.getStratReportResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 60000);
    }

    render() {
        const breadCrumbItems = [
            {label: 'Report Creation'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Toast ref={(el) => this.toastTL = el} position="top-left" />
                <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/dashboard");
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Report Creation</h1>
                {this.isProgressBar()}
            </div>
        );
    }
}
