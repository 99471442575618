import React, { Component } from 'react';
import './Landing.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Authkey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authorizationKey: ""
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    async nextPath(path) {
        this.props.history.push({
            pathname: path
        });
    }

    componentDidMount() {
        if(this.props.location.keyData !== undefined) {
            this.setState({ authorizationKey: this.props.location.keyData });
        }
    }

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="p-md-12">
                        <div className="p-md-12">
                            <p>Your athorization key is: {this.state.authorizationKey}</p>
                        </div>
                    </div>
                    <div className="p-md-6 button-pane">
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Go To Login" onClick={() => {this.nextPath('/login')}} />
                    </div>
                </div>
            </header>
        );
    }
}
