
import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import './Registration.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';

export class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUserName: this.props.location.mUser.username,
            mFirstName: this.props.location.mUser.first_name,
            mLastName: this.props.location.mUser.last_name,
            mUserEmail: this.props.location.mUser.email,
            mCellNumber: this.props.location.mUser.contact_number,
            mCompanyAdmin: this.props.location.mUser.is_an_admin,
            mActive: this.props.location.mUser.is_active,
            mPassword: '',
            mConfirmPassword: '',
            reportsBusy: false
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successfully generated the required reports!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful in generating the required reports!', life: 5000});
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <ProgressSpinner/>
                    <span className="guest-sign-in">CTIEP Registration in progress</span>
                </React.Fragment>
            );
        } else {
            const header = (
                <h6>Strong Password Required</h6>
            );
            const footer = (
                <React.Fragment>
                    <Divider />
                    <p className="p-mt-2">Suggestions</p>
                    <ul className="p-pl-2 p-ml-2 p-mt-0" style={{lineHeight: '1.0'}}>
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </React.Fragment>
            );
            return (
                <React.Fragment>
                    <div className="form-box">
                        <div className="body-form">
                            <form name="Form">
                                <div className="p-grid" >
                                    <div className="p-col-3" style={{ textAlign: 'left' }}>
                                        <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                                    </div>
                                    <div className="p-col-9" style={{ textAlign: 'left' }}>
                                        <h2 className="welcome-text">User Registration</h2>
                                    </div>
                                    <div className="p-col-12">
                                        <fieldset>
                                        <legend>User:</legend>
                                        <div className="p-col-12" style={{ textAlign: 'left' }}>
                                            <div className="login-input">
                                                <small className="p-error p-d-block">*User Name</small>
                                                <InputText readOnly id="userName" className="p-col-12 flatLookInputText" placeholder={this.state.mUserName} value={this.state.mUserName}/>
                                            </div>
                                            <div className="login-input">
                                                <small className="p-error p-d-block">*First Name</small>
                                                <InputText readOnly id="firstName" className="p-col-12 flatLookInputText" placeholder={this.state.mFirstName} value={this.state.mFirstName} />
                                            </div>
                                            <div className="login-input">
                                                <small className="p-error p-d-block">*Last Name</small>
                                                <InputText readOnly id="lastName" className="p-col-12 flatLookInputText" placeholder={this.state.mLastName} value={this.state.mLastName} />
                                            </div>
                                            <div className="login-input">
                                                <small className="p-error p-d-block">*Email</small>
                                                <InputText readOnly id="email1" type="email" className="p-col-12 flatLookInputText" placeholder={this.state.mUserEmail} value={this.state.mUserEmail} />
                                            </div>
                                            <div className="login-input">
                                                <small className="p-error p-d-block">*Cell Number</small>
                                                <InputText readOnly id="cellNumber" className="p-col-12 flatLookInputText" placeholder={this.state.mCellNumber}  value={this.state.mCellNumber} />
                                            </div>
                                            <div className="p-password-input">
                                                <small className="p-error p-d-block">*Password</small>
                                                <Password required id="passwordEnter" className="p-col-12 flatLookInputText p-password-info" placeholder="Password" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} header={header} footer={footer}/>
                                                <small className="p-error p-d-block">*Confirm Password</small>
                                                <Password required id="passwordConfirm" className="p-col-12 flatLookInputText p-password-info" placeholder="Confirm Password" value={this.state.mConfirmPassword} onChange={(e) => this.setState({mConfirmPassword: e.target.value})} header={header} footer={footer} />
                                            </div>
                                        </div>
                                        </fieldset>
                                    </div>
                                    <div className="p-col-12 button-pane" style={{ textAlign: 'center' }}>
                                        <Button label="Register" onClick={ (e) => {
                                            e.preventDefault();
                                            this.nextPath('/log', '/');
                                        }}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    async nextPath(path, prev) {
        const registration = {
            "username": this.state.mUserName,
            "first_name": this.state.mFirstName,
            "last_name": this.state.mLastName,
            "email": this.state.mUserEmail,
            "contact_number": "12345",
            "is_active": this.state.mCompanyAdmin,
            "is_an_admin": this.state.mActive,
            "password": this.state.mPassword,
            "password2": this.state.mConfirmPassword
        };

        this.setState({reportsBusy: true});
        this.productServiceDataSources.doRegistration(registration);
        setTimeout(() => {
            if(this.productServiceDataSources.getRegResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
                this.props.history.push({
                    pathname: path
                });
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 7000);
    }

    render() {
        return(
            <div className="container">
                <Toast ref={(el) => this.toast = el} />
                <Toast ref={(el) => this.toastTL = el} position="top-left" />
                <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                {this.isProgressBar()}
            </div>
        );
    }
}