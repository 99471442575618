import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export class Exchange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportsBusy: false
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successful data exchange bewteen platforms!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful data exchange bewteen platforms!', life: 5000});
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}}>
                        <ProgressSpinner/>
                        <p>Performing data exchange bewteen platforms</p>
                    </Card>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}}>
                        <p>Click below to perform data exchange bewteen platforms</p>
                        <Button className="custom" label="Share Platform Reports" onClick={(e) => {
                            e.preventDefault();
                        }} />
                        <div className="divider"/>
                        <Button className="custom" label="Fetch Central Reports" onClick={(e) => {
                            e.preventDefault();
                            this.fetchReports();
                        }} />
                    </Card>
                </React.Fragment>
            );
        }
    }

    async fetchReports() {
        this.setState({reportsBusy: true});
        this.productServiceDataSources.reportGetter();
        setTimeout(() => {
            if(this.productServiceDataSources.getAllReports() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 10000);
    }

    render() {
        const breadCrumbItems = [
            {label: 'Exchange Reports'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Toast ref={(el) => this.toastTL = el} position="top-left" />
                <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/dashboard");
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Exchange Reports</h1>
                {this.isProgressBar()}
            </div>
        );
    }
}
