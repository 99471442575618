import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';

export class DataSources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getSeverity(thisState) {
        let state_level = null;
        if(thisState.level === "Information") {
            state_level = "../assets/demo/images/level/Information.png";
        } else if(thisState.level === "Critical") {
            state_level = "../assets/demo/images/level/Critical.png";
        } else if(thisState.level === "Warning") {
            state_level = "../assets/demo/images/level/Warning.png";
        }

        return (
            <React.Fragment>
                <img alt="state" src={state_level} width={75} />
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.productServiceDataSources.getDataSourcesInfo().then(data => this.setState({ items: data }));
    }

    render() {
        const breadCrumbItems = [
            {label: 'Collections', url: '/#/dashboard'},
            {label: 'Data Sources'}
        ];
        const home = { icon: 'pi pi-home', url: '/#/dashboard' };

        return (
            <div>
                <div className="card p-grid">
                    <Button label="Back" icon="pi pi-arrow-left" className="p-col-1" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/dashboard");
                    }} style={{marginRight: '5px'}}/>
                    <BreadCrumb model={breadCrumbItems} home={home}  className="p-col-10"/>
                </div>
                <h1>Data Sources</h1>
                <div className="card">
                    <DataTable value={this.state.items}>
                        <Column field="source" header="Data Source"></Column>
                        <Column field="type" header="Threat Type"></Column>
                        <Column field="description" header="Threat Description"></Column>
                        <Column field="level" header="Severity" body={this.getSeverity}></Column>
                        <Column field="date" header="Date"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}