import React, { useEffect } from 'react';
import { Route, useLocation, withRouter, useHistory } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import { Wizard } from './pages/Wizard';
import axios from 'axios';

const AppWrapper = () => {

    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    switch (location.pathname) {
        case '/':
            /*let mKeyResult = '';
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/accounts/check-activation/').then((res) => {mKeyResult = res.data.activation_status});
            if(mKeyResult !== false) {
                history.push({
                    pathname: '/login'
                });
                return <Route path="/login" component={Login} />
            } else {
                return <App />;
            }*/
            return <App />;
        case '/login':
            return <Route path="/login" component={Login} />
        case '/error':
            return <Route path="/error" component={Error} />
        case '/notfound':
            return <Route path="/notfound" component={NotFound} />
        case '/access':
            return <Route path="/access" component={Access} />
        case '/wizard':
            return <Route path="/wizard" component={Wizard} />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);
