import React, { Component } from 'react';
import './Landing.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

export class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mAuthKey: ''
        };
    }

    async nextPath(path) {
        const authKey = {"key": this.state.mAuthKey};
        let mKeyResult = '';
        await axios.post('https://s2ctiepapi.urithi.co.za/capi/v1/accounts/activate-product/', authKey).then(res => {mKeyResult = res.data});
        this.props.history.push({
            pathname: path,
            mUser: mKeyResult
        });
    }

    componentDidMount() {
        let mKeyResult = '';
        axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/accounts/check-activation/').then((res) => {
            mKeyResult = res.data.activation_status;
            if(mKeyResult !== false) {
                this.props.history.push({pathname: '/login'});
            } else {
                this.props.history.push({pathname: '/'});
            }
        });
    }

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="rowToColumn p-md-12">
                        <div className="p-md-12">
                            <p>Provide the Authorization Key to activate Stakeholder CTIEP</p>
                            <div className="login-input longer">
                                <InputText placeholder="Paste Authorization Key" value={this.state.mAuthKey} onChange={(e) => this.setState({mAuthKey: e.target.value})} />
                            </div>
                        </div>
                    </div>
                    <div className="p-md-6 button-pane">
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Submit" onClick={() => {this.nextPath('/registration')}} />
                    </div>
                </div>
            </header>
        );
    }
}
