import axios from 'axios';
import React, { Component } from 'react';

export default class ServiceAllApiData extends Component {
    static mKey = '';
    static mUser = '';
    static mExchangeTactical = '';
    static mExchangeStrategic = '';
    static mExchangeOperational = '';
    static mCreateTactical = '';
    static mCreateStrategic = '';
    static mCreateOperational = '';
    static mFetchTactical = '';
    static mFetchtrategic = '';
    static mFetchOperational = '';
    static mLogin = '';
    static mRegister = '';
    static mAggCvesKeys = '';
    static mAggCvesValues = '';
    static mAggCvesColors = '';
    static mAggMispKeys = '';
    static mAggMispValues = '';
    static mAggMispColors = '';
    static mAggUrlKeys = '';
    static mAggUrlValues = '';
    static mAggUrlColors = '';
    static mAggUrlCoords = '';

    async setGlobalKey(myKey) {
        ServiceAllApiData.mKey = myKey;
        this.setCveAggregationData();
        this.setMispAggregationData();
        this.setUrlAggregationData();
        this.setUrlAggregationCoords();
    }

    async getGlobalKey() {
        return ServiceAllApiData.mKey;
    }

    getCveAggregationKeys() {
        return ServiceAllApiData.mAggCvesKeys;
    }

    getCveAggregationValues() {
        return ServiceAllApiData.mAggCvesValues;
    }

    getMispAggregationKeys() {
        return ServiceAllApiData.mAggMispKeys;
    }

    getMispAggregationValues() {
        return ServiceAllApiData.mAggMispValues;
    }

    getMispAggregationColors() {
        return ServiceAllApiData.mAggMispColors;
    }

    getCveAggregationColors() {
        return ServiceAllApiData.mAggCvesColors;
    }

    async getMispIdSourcesInfo(mispId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/misp/'.concat(mispId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async performExchange() {
        let myKey = ServiceAllApiData.mKey;
        let mKeyResult = '';
        let mKeyResult1 = '';
        let mKeyResult2 = '';
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=STRATEGIC&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResult = res.status;
                if((mKeyResult === 200 || mKeyResult === 201 ))
                {
                    ServiceAllApiData.mExchangeStrategic = "success";
                } else {
                    ServiceAllApiData.mExchangeStrategic = "unsuccess";
                }
            }),
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=OPERATIONAL&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResult1 = res.status;
                if((mKeyResult1 === 200 || mKeyResult1 === 201 ))
                {
                    ServiceAllApiData.mExchangeOperational = "success";
                } else {
                    ServiceAllApiData.mExchangeOperational = "unsuccess";
                }
            }),
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=TACTICAL&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResult2 = res.status;
                if((mKeyResult2 === 200 || mKeyResult2 === 201 ))
                {
                    ServiceAllApiData.mExchangeTactical = "success";
                } else {
                    ServiceAllApiData.mExchangeTactical = "unsuccess";
                }
            })
        ]);
    }

    getExchangeResult() {
        if(ServiceAllApiData.mExchangeTactical === "success" && ServiceAllApiData.mExchangeOperational === "success" && ServiceAllApiData.mExchangeStrategic === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async createReports(fromDate, toDate) {
        let myKey = ServiceAllApiData.mKey;
        let mKeyResultTactical = '';
        let mKeyResultOperational = '';
        let mKeyResultStrategic = '';
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/tactical/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultTactical = res.status;
                if((mKeyResultTactical === 200 || mKeyResultTactical === 201 ))
                {
                    ServiceAllApiData.mCreateTactical = "success";
                } else {
                    ServiceAllApiData.mCreateTactical = "unsuccess";
                }
            }),
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/operational/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultOperational = res.status;
                if((mKeyResultOperational === 200 || mKeyResultOperational === 201 ))
                {
                    ServiceAllApiData.mCreateOperational = "success";
                } else {
                    ServiceAllApiData.mCreateOperational = "unsuccess";
                }
            }),
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/strategic/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultStrategic = res.status;
                if((mKeyResultStrategic === 200 || mKeyResultStrategic === 201 ))
                {
                    ServiceAllApiData.mCreateStrategic = "success";
                } else {
                    ServiceAllApiData.mCreateStrategic = "unsuccess";
                }
            })
        ]);
    }

    getReportResult() {
        if(ServiceAllApiData.mCreateTactical === "success" && ServiceAllApiData.mCreateOperational === "success" && ServiceAllApiData.mCreateStrategic === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async createTacReports(fromDate, toDate) {
        let myKey = ServiceAllApiData.mKey;
        let mKeyResultTactical = '';
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/tactical/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultTactical = res.status;
                if((mKeyResultTactical === 200 || mKeyResultTactical === 201 ))
                {
                    ServiceAllApiData.mCreateTactical = "success";
                } else {
                    ServiceAllApiData.mCreateTactical = "unsuccess";
                }
            }),
        ]);
    }

    getTacReportResult() {
        if(ServiceAllApiData.mCreateTactical === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async createOpsReports(fromDate, toDate) {
        let myKey = ServiceAllApiData.mKey;
        let mKeyResultOperational = '';
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/operational/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultOperational = res.status;
                if((mKeyResultOperational === 200 || mKeyResultOperational === 201 ))
                {
                    ServiceAllApiData.mCreateOperational = "success";
                } else {
                    ServiceAllApiData.mCreateOperational = "unsuccess";
                }
            }),
        ]);
    }

    getOpsReportResult() {
        if(ServiceAllApiData.mCreateOperational === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async createStratReports(fromDate, toDate) {
        let myKey = ServiceAllApiData.mKey;
        let mKeyResultStrategic = '';
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/strategic/create/?date_from=' + fromDate + '&date_to=' + toDate, {'headers': {'Authorization': 'Token '.concat(myKey)}}).then((res) => {
                mKeyResultStrategic = res.status;
                if((mKeyResultStrategic === 200 || mKeyResultStrategic === 201 ))
                {
                    ServiceAllApiData.mCreateStrategic = "success";
                } else {
                    ServiceAllApiData.mCreateStrategic = "unsuccess";
                }
            }),
        ]);
    }

    getStratReportResult() {
        if(ServiceAllApiData.mCreateStrategic === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async doLogin(login, username) {
        let mKey = "";
        let mResult = '';
        await Promise.all([
            axios.post('https://s2ctiepapi.urithi.co.za/capi/v1/rest-auth/login/', login).then(res => {
                mKey = res.data.key;
                mResult = res.request.status;
                if(mResult === 200 || mResult === 201) {
                    this.setGlobalKey(mKey);
                    this.setLoggedInUser(username);
                    ServiceAllApiData.mLogin = "success";
                } else {
                    ServiceAllApiData.mLogin = "unsuccess";
                }
            }),
        ]);
    }

    getLoginResult() {
        if(ServiceAllApiData.mLogin === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async setMispAggregationData() {
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/misp/threat_level_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.mKey)}}).then((res) => {
                let key = [];
                let value = [];
                let color = [];
                let aggMisp = res.data.results;
                setTimeout(() => {
                    let threat_levels = aggMisp;
                    if(threat_levels !== undefined) {
                        threat_levels.map((threat_level) => {
                            if(threat_level.id === "4") {
                                key.push("UNDEFINED");
                                color.push('rgba(0, 0, 255, 1)');
                            } else if(threat_level.id === "2") {
                                key.push("MEDIUM");
                                color.push('rgba(255, 200, 0, 1)');
                            } else if(threat_level.id === "3") {
                                key.push("LOW");
                                color.push('rgba(60, 179, 113, 1)');
                            } else if(threat_level.id === "1") {
                                key.push("HIGH");
                                color.push('rgba(255, 165, 0, 1)');
                            }
                            value.push(threat_level.total);
                        });
                    }
                    ServiceAllApiData.mAggMispKeys = key;
                    ServiceAllApiData.mAggMispValues = value;
                    ServiceAllApiData.mAggMispColors = color;
                }, 2000 );
            }),
        ]);
    }

    async setUrlAggregationData() {
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/urlblacklist/url_status_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.mKey)}}).then((res) => {
                let key = [];
                let value = [];
                let color = [];
                let aggUrl = res.data.results;
                setTimeout(() => {
                    let urls = aggUrl;
                    if(urls !== undefined) {
                        urls.map((url) => {
                            key.push(url.id);
                            value.push(url.total);
                            if(url.id === "online") {
                                color.push('rgba(60, 179, 113, 1)');
                            } else {
                                color.push('rgba(255, 0, 0, 1)');
                            }
                        });
                    }
                    ServiceAllApiData.mAggUrlKeys = key;
                    ServiceAllApiData.mAggUrlValues = value;
                    ServiceAllApiData.mAggUrlColors = color;
                }, 2000 );
            }),
        ]);
    }

    async setUrlAggregationCoords() {
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/urlblacklist/url_location_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.mKey)}}).then((res) => {
                let latLon = [];
                let urlLocations = res.data.results;
                setTimeout(() => {
                    let locations = urlLocations;
                    if(locations !== undefined) {
                        locations.map((location) => {
                            latLon.push(
                                {
                                    'lat': location.lat,
                                    'lon': location.lon,
                                    'country': location.country,
                                    'detect': location.num_of_detections,
                                    'city': location.city,
                                    'ip': location.ip,

                                }
                            );
                        });
                    }
                    ServiceAllApiData.mAggUrlCoords = latLon;
                }, 4000 );
            }),
        ]);
    }

    async setCveAggregationData() {
        await Promise.all([
            axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/cves/severity_aggregation/', {'headers': {'Authorization': 'Token '.concat(ServiceAllApiData.mKey)}}).then((res) => {
                let key = [];
                let value = [];
                let severity = [];
                let aggCves = res.data.results;
                setTimeout(() => {
                    let severity_levels = aggCves;
                    if(severity_levels !== undefined) {
                        severity_levels.map((severity_level) => {
                            if(severity_level.id === ""){
                                key.push("INFO");
                            } else {
                                key.push(severity_level.id);
                            }
                            value.push(severity_level.total);

                            if(severity_level.id === "") {
                                severity.push('rgba(0, 0, 255, 1)');
                            }else if(severity_level.id === "CRITICAL") {
                                severity.push('rgba(255, 0, 0, 1)');
                            }else if(severity_level.id === "HIGH") {
                                severity.push('rgba(255, 165, 0, 1)');
                            }else if(severity_level.id === "MEDIUM") {
                                severity.push('rgba(255, 200, 0, 1)');
                            }else if(severity_level.id === "LOW") {
                                severity.push('rgba(60, 179, 113, 1)');
                            }
                        });
                    }
                    ServiceAllApiData.mAggCvesKeys = key;
                    ServiceAllApiData.mAggCvesValues = value;
                    ServiceAllApiData.mAggCvesColors = severity;
                }, 2000 );
            }),
        ]);
    }

    async doRegistration(registration) {
        let mResult = '';
        await Promise.all([
            axios.post('https://s2ctiepapi.urithi.co.za/capi/v1/rest-auth/registration/', registration).then(res => {
                mResult = res.request.status
                if(mResult === 200 || mResult === 201) {
                    ServiceAllApiData.mRegister = "success";
                } else {
                    ServiceAllApiData.mRegister = "unsuccess";
                }
            }),
        ]);
    }

    getRegResult() {
        if(ServiceAllApiData.mRegister === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }


    async setLoggedInUser(myUser) {
        ServiceAllApiData.mUser = myUser;
    }

    getLoggedInUser() {
        return ServiceAllApiData.mUser;
    }

    getSiemEventInfo() {
        return axios.get('assets/demo/data/siem-events.json').then((res) => res.data.data);
    }

    getIncidentsInfo() {
        return axios.get('assets/demo/data/incidents.json').then((res) => res.data.data);
    }

    getDataSourcesInfo() {
        return axios.get('assets/demo/data/data-sources.json').then((res) => res.data.data);
    }

    getNotifications() {
        return axios.get('assets/demo/data/notifications.json').then((res) => res.data.data);
    }

    getReportingData() {
        return axios.get('assets/demo/data/reporting.json').then((res) => res.data.data);
    }

    async getCveDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/cves/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    getUrlAggregationKeys() {
        return ServiceAllApiData.mAggUrlKeys;
    }

    getUrlAggregationValues() {
        return ServiceAllApiData.mAggUrlValues;
    }

    getUrlAggregationColors() {
        return ServiceAllApiData.mAggUrlColors;
    }

    getUrlAggregationCoords() {
        return ServiceAllApiData.mAggUrlCoords;
    }

    async getMispData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/misp/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getCveIdSourcesInfo(cveId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/cves/'.concat(cveId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getMalwareDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/malware/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getBlacklistDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/urlblacklist/' , {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getOperationalReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/operational/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/tactical/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/strategic/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/tactical/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getOperationalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/operational/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/reports/strategic/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getReportIdInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/cves/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data.results);
    }

    async getMalwareIdSourcesInfo(malwareId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/malware/'.concat(malwareId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getBlacklistIdSourcesInfo(blacklistId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/urlblacklist/'.concat(blacklistId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getIndustriesInfo() {
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/accounts/industries/').then(res => res.data);
    }

    async getProvincesInfo() {
        return axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/proxy/?url=https://ctiepapi.urithi.co.za/capi/v1/accounts/provinces/').then(res => res.data);
    }

    async reportGetter() {
        let mKeyResultTactical = '';
        await Promise.all([
            axios.get('https://ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=TACTICAL&url=s2ctiepapi.urithi.co.za').then((res) => {
                mKeyResultTactical = res.status;
                if(mKeyResultTactical === 200 || mKeyResultTactical === 201 )
                {
                    ServiceAllApiData.mFetchTactical = "success";
                } else {
                    ServiceAllApiData.mFetchTactical = "unsuccess";
                }
            }),
            axios.get('https://ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=OPERATIONAL&url=s2ctiepapi.urithi.co.za').then((res) => {
                mKeyResultTactical = res.status;
                if(mKeyResultTactical === 200 || mKeyResultTactical === 201 )
                {
                    ServiceAllApiData.mFetchOperational = "success";
                } else {
                    ServiceAllApiData.mFetchOperational = "unsuccess";
                }
            }),
            axios.get('https://ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=STRATEGIC&url=s2ctiepapi.urithi.co.za').then((res) => {
                mKeyResultTactical = res.status;
                if(mKeyResultTactical === 200 || mKeyResultTactical === 201 )
                {
                    ServiceAllApiData.mFetchStrategic = "success";
                } else {
                    ServiceAllApiData.mFetchStrategic = "unsuccess";
                }
            }),
        ]);
    }

    getAllReports() {
        if(ServiceAllApiData.mFetchTactical === "success" && ServiceAllApiData.mFetchOperational === "success" && ServiceAllApiData.mFetchStrategic === "success")
        {
            return "success";
        } else {
            return "unsuccess";
        }
    }

    async useGlobalKey(path) {
        let mKeyResult = ServiceAllApiData.mKey;
        let mKeyResult1 = '';
        let mKeyResult2 = '';
        let mKeyResult3 = '';
        await axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=STRATEGIC&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => {mKeyResult1 = res});
        await axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=OPERATIONAL&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => {mKeyResult2 = res});
        await axios.get('https://s2ctiepapi.urithi.co.za/capi/v1/exchange/request-reports/?message_type=TACTICAL&url=ctiepapi.urithi.co.za', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => {
            if(res === 200 || res === 201) {
                this.props.history.push({
                    pathname: path
                });
            }
            mKeyResult3 = res;
        });
    }

    useGlobalAdmin() {
        let mLoggedInUser = ServiceAllApiData.mUser + ' ';
        return (
            <React.Fragment>
                <span className="profile-name">{mLoggedInUser}</span>
            </React.Fragment>
        );
    }
}
